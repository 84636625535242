import {DataHandler} from 'o365-dataobject';
import {AzureStorageApi} from 'o365-azure';
import {DataObject} from 'o365-dataobject';
export default class AzureTableStorageHandler extends DataHandler{
    private _tableClient:AzureStorageApi.TableClient;
    private _rowKeyField:string = "PrimKey";
    private _partitionKeyField:string;
    private __dataObject:DataObject;
   

    set dataObject(pValue){
        super._dataObject = pValue;
        this.__dataObject = pValue;
        window.setTimeout(()=>{
            this.__dataObject.on("BeforeSave",(pData,pRow)=>{
                if(!pData.values.hasOwnProperty(this._partitionKeyField)){
                    pData.values[this._partitionKeyField] = pRow[this._partitionKeyField];
                }

                if(!pRow["PrimKey"]){
                    pData.values = pRow;
                }
            })
        },10);
        
    }

    setDataObject(pDataObject) {
        this.dataObject = pDataObject;
    }
    
    constructor(pOptions:any){
        super(null);
        this._tableClient = new AzureStorageApi.TableClient(pOptions);

        this._partitionKeyField = pOptions["partitionKey"];
        this._rowKeyField = pOptions["rowKey"];
    }
     async request(pType: string, pData: any, pHeaders?: any, pOptions?: any){
        switch(pType){
            case "retrieve":
                return this._query();
            case "update":
            case "create":
                return this._createOrUpdate(pData);
            

        }
      
    }

    private async _query(){
        const vResult =  await this._tableClient.queryTableEntities();
        return this._formatEntityData(vResult['data']);
    }
    private async _createOrUpdate(pData){
        if(!pData.values[this._partitionKeyField]){
            throw this._partitionKeyField + " field is required.";
        }

        if(!pData.values["PrimKey"]){
            pData.values["PrimKey"] = generateUUID();
        }
        if(!pData.values["PrimKey"]){
            pData.values["PrimKey"] = generateUUID();
        }

        pData.values["partitionKey"] = pData.values[this._partitionKeyField];
        delete pData.values[this._partitionKeyField];
        pData.values["rowKey"] = pData.values["PrimKey"];
        delete pData.values["PrimKey"];



        var vResult = await this._tableClient.updateTableEntity({
            entity: pData.values,
            upsert: true
           // updateMode: AzApi.TableEntityUpdateMode.Replace
        });
        if(vResult.hasOwnProperty("Exception")){
            throw vResult;
        }

        pData.values[this._partitionKeyField] = pData.values["partitionKey"];
        delete pData.values["partitionKey"];
        pData.values["PrimKey"] = pData.values["rowKey"];
        delete pData.values["rowKey"];


        return [pData.values];
    }

    private _formatEntityData(pData:Array<any>){
        const vReturn = [];
        pData.forEach(row=>{
            row["PrimKey"] = row["RowKey"];
            row[this._partitionKeyField] = row["PartitionKey"];
            delete row["RowKey"];
            delete row["PartitionKey"];
            this.__dataObject.fields.fields.forEach(field=>{
                if(!row.hasOwnProperty(field.name)){
                    row[field.name] = null;
                }
            })
            vReturn.push(row);
        })

        return vReturn;
       
    }

}

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}